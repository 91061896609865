<template>
  <div class="outer">
    <DetailTitle title="收款账户信息" />
    <div
      v-if="pageType !== 'add'"
      style="paddingLeft: 10px;marginBottom: 20px; fontWeight: 700; fontSize: 16px;"
    >
      审核状态：
      {{ form.status == 1 ? '待审核': form.status == 2 ? '审核通过' : `审核不通过${form.auditNote !== null && form.auditNote !== "" && form.auditNote !== 'null' ? '（'+form.auditNote+ '）' : ''}` }}
    </div>
    <div class="result-content">
      <div class="result-status">
        <i :class="`iconfont ${tip.icon}`"></i>
      </div>
      <div class="result-info">
        <h3 style="font-size: 16px;">
          {{ tip.title }}
        </h3>
        <p :style="{'color': form.status === -1 ? '#000' : 'red'}">
          {{ tip.content }}
        </p>
      </div>
    </div>

    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="150px"
      class="bank-info"
    >
      <el-form-item
        label="收款单位名称："
        prop="receiveUnitName"
      >
        <el-input
          v-model="form.receiveUnitName"
          placeholder="请输入公司名称"
          class="input-w300"
        />
      </el-form-item>
      <el-form-item
        label="收款账号："
        prop="receiveAccount"
      >
        <el-input
          v-model="form.receiveAccount"
          placeholder="请输入收款账号"
          class="input-w300"
        />
      </el-form-item>
      <el-form-item
        label="收款账户开户行："
        prop="accountBankName"
      >
        <el-input
          v-model="form.accountBankName"
          placeholder="请填写开户行全称"
          class="input-w300"
        />
      </el-form-item>
      <el-form-item
        label="银行名称："
        prop="bankCode"
      >
        <el-select
          v-model="form.bankCode"
          placeholder="请选择银行"
        >
          <el-option
            v-for="item in bankList"
            :key="item.bankCode"
            :label="item.bankName"
            :value="item.bankCode"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="开户行省市："
        prop="areaList"
      >
        <el-cascader
          ref="areaRef"
          v-model="form.areaList"
          class="width360"
          :options="catList"
          :props="{
            checkStrictly: true,
            value: 'regionCode',
            label: 'regionName',
            expandTrigger: 'hover'
          }"
          clearable
          size="mini"
          placeholder="请选择"
          @change="handleChange"
        />
      </el-form-item>
      <el-form-item
        label="行联号："
        prop="bankJointCode"
      >
        <el-input
          v-model="form.bankJointCode"
          placeholder="请填写行联号"
          class="input-w300"
          maxlength="20"
          show-word-limit
          @input="form.bankJointCode = form.bankJointCode.replace(/\D/g, '')"
        />
        <a
          class="table-controller"
          href="https://www.cwjyz.com.cn/bank/index.html"
          target="_blank"
        >查询参考：https://www.cwjyz.com.cn/bank/index.html</a>
      </el-form-item>
      <el-form-item
        label="开户许可证(或“基本存款账户信息”、“单位结算卡”)："
        prop="openPermitImgPath"
      >
        <LoadImgOss
          :multiple="false"
          :priview="false"
          @onSuccess="handleImgChange"
        />
        <div
          v-if="form.openPermitImgPath"
          style="margin-top: 20px;"
        >
          <img
            :src="form.openPermitImgPath"
            alt=""
            class="open-img"
          />
        </div>
        <p class="remark">
          只能上传jpg/png文件，大小不超过2Mb，数量仅限1张
        </p>
      </el-form-item>
      <el-form-item
        v-if="pageType !== 'add'"
        label="营业执照："
        prop="licenseImage"
      >
        <LoadImgOss
          :multiple="false"
          :priview="false"
          :disabled="true"
          @onSuccess="handleLicenseImageChange"
        />
        <div
          v-if="form.licenseImage"
          style="margin-top: 20px;"
        >
          <img
            :src="form.licenseImage"
            alt=""
            class="open-img"
          />
        </div>
        <p class="remark">
          只能上传jpg/png文件，大小不超过2Mb，数量仅限1张
        </p>
      </el-form-item>
      <el-form-item
        v-if="pageType !== 'add'"
        label="身份证正面："
        prop="identityCardImageFont"
      >
        <LoadImgOss
          :multiple="false"
          :priview="false"
          :disabled="true"
          @onSuccess="handleIdentityCardImageFontChange"
        />
        <div
          v-if="form.identityCardImageFont"
          style="margin-top: 20px;"
        >
          <img
            :src="form.identityCardImageFont"
            alt=""
            class="open-img"
          />
        </div>
        <p class="remark">
          只能上传jpg/png文件，大小不超过2Mb，数量仅限1张
        </p>
      </el-form-item>
      <el-form-item
        v-if="pageType !== 'add'"
        label="身份证反面："
        prop="identityCardImageBack"
      >
        <LoadImgOss
          :multiple="false"
          :priview="false"
          :disabled="true"
          @onSuccess="handleIdentityCardImageBackChange"
        />
        <div
          v-if="form.identityCardImageBack"
          style="margin-top: 20px;"
        >
          <img
            :src="form.identityCardImageBack"
            alt=""
            class="open-img"
          />
        </div>
        <p class="remark">
          只能上传jpg/png文件，大小不超过2Mb，数量仅限1张
        </p>
      </el-form-item>
      <el-form-item>
        <el-button
          v-if="form.status !== 4 || form.status !== 7"
          type="primary"
          @click="updateInfo"
        >
          提交
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/**
 * 供应商银行信息审核页面
 */
import DetailTitle from '@/components/common/DetailTitle';
import LoadImgOss from '@/components/common/loadImgOss';
import {
  FORM_RULES,
  FORM_VALUES,
  AUDIT_TYPES,
} from './normals';

export default {
  name: 'supply-bank',
  components: { DetailTitle, LoadImgOss },
  data() {
    return {
      isRead: 1, // 权限
      pageType: 'edit',
      // form: FORM_VALUES,
      form: {
        receiveUnitName: '',
        receiveAccount: '',
        accountBankName: '',
        bankCode: '',
        areaList: [],
        openPermitImgPath: '',
        identityCardImageBack: '',
        identityCardImageFont: '',
        licenseImage: '',
        status: '',
        auditNote: '',
        bankJointCode: '',
      },
      rules: FORM_RULES,
      tipArr: AUDIT_TYPES,
      tip: {
        icon: '',
        title: '',
        content: '请务必仔细核对以下信息，避免因信息填写错误而造成的打款损失！',
        status: 0,
      },
      isEditBtn: false,
      supplierId: '',
      bankList: [],
      catList: [],
    };
  },
  mounted() {
    this.pageType = this.$route.query.type;
    this.supplierId = JSON.parse(localStorage.getItem('userInfo')).suppliersId;
    this.getInfo();
    this.getBankList();
  },
  methods: {
    updateInfo() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm('是否确认提交该信息', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(() => {
            this.$axios({
              method: 'post',
              url: this.$api.settlement.update,
              data: this.form,

            }).then((res) => {
              let r = res;
              if (r.code === 0) {
                this.$message.success('提交成功');
                this.$router.replace('/nb/setting/store/list');
              }
              this.getInfo();
            });
          });
        } else {
          this.$message.error('请完善信息');
          return false;
        }
      });
    },
    // 修改
    modifyInfo() {
      this.isEditBtn = true;
    },
    // 获取信息
    getInfo() {
      let mapArr = (arr, name) => {
        const imgData = {
          fileList: [],
          imgCode: [],
        };
        arr.forEach((item) => {
          imgData.fileList.push({ url: item.img_url, name });
          imgData.imgCode.push(item.img_id);
        });
        return imgData;
      };
      this.$axios({
        method: 'get',
        url: this.$api.settlement.get,
        params: {
          supplierId: this.supplierId,
        },
      }).then((res) => {
        let r = res;
        if (r.code === 0) {
          this.form.receiveUnitName = r.data.receiveUnitName;
          this.form.receiveAccount = r.data.receiveAccount;
          this.form.accountBankName = r.data.accountBankName;
          this.form.supplierId = r.data.supplierId;
          this.form.openPermitImgPath = r.data.openPermitImgPath;
          this.form.licenseImage = r.data.licenseImage;
          this.form.identityCardImageFont = r.data.identityCardImageFont;
          this.form.identityCardImageBack = r.data.identityCardImageBack;
          this.form.status = r.data.financialStatus;
          this.form.remark = r.data.remark;
          this.form.accountProvince = r.data.accountProvince;
          this.form.accountCity = r.data.accountCity;
          this.form.bankCode = r.data.bankCode;
          this.form.auditNote = r.data.auditNote;
          this.form.bankJointCode = r.data.bankJointCode;
          this.form.areaList = r.data.accountProvince ? [r.data.accountProvince, r.data.accountCity] : null;
          const arr = [r.data.accountProvince, r.data.accountCity];
          this.getAllCatList(arr);
          if (this.form.status === 4) {
            this.tip = this.tipArr[0];
          } else if (this.form.status === 5) {
            this.tip = this.tipArr[1];
          } else if (this.form.status === 6) {
            this.tip = this.tipArr[2];
            this.tip.content = this.tip.content + this.form.auditNote;
          }
        }
      });
    },
    handleImgChange(data) {
      this.form.openPermitImgPath = data[0].url;
    },
    handleLicenseImageChange(data) {
      this.form.licenseImage = data[0].url;
    },
    handleIdentityCardImageFontChange(data) {
      this.form.identityCardImageFont = data[0].url;
    },
    handleIdentityCardImageBackChange(data) {
      this.form.identityCardImageBack = data[0].url;
    },
    getBankList() {
      this.$axios({
        method: 'get',
        url: this.$api.commonNew.getBankList,
      }).then((res) => {
        let r = res;
        if (r.code === 0) {
          this.bankList = r.data;
        }
      });
    },
    handleChange(value) {
      console.log(value);
      let _this = this;
      let id = value[value.length - 1];
      this.form.siteId = id;
      this.form.accountProvince = value[0] ? value[0] : null;
      this.form.accountCity = value[1] ? value[1] : null;
      // this.ruleForm.areaCode = value[2] ? value[2] : null;

      if (!value || value.length >= 2) {
        return;
      }
      let findCatList = (array) => {
        array.forEach((item) => {
          if (item.regionCode == id) {
            _this.getAddressList(id, item);
          } else if (item.children) {
            findCatList(item.children);
          }
        });
      };
      findCatList(_this.catList);
    },
    // 获得地址数据
    getAddressList(id, obj) {
      let that = this;
      if (obj && obj.children) {
        return;
      }
      that
        .$axios({
          method: 'get',
          url: that.$api.commonNew.getChildRegion,
          params: {
            regionCode: id,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            if (res.data.length > 0) {
              if (id == 0) {
                that.options = res.data;
              } else {
                that.$set(obj, 'children', res.data);
              }
            }
          }
        });
    },
    getNowCat(id) {
      let that = this;
      return new Promise((resolve) => {
        that
          .$axios({
            method: 'get',
            url: that.$api.commonNew.getChildRegion,
            params: {
              regionCode: id,
            },
          })
          .then((res) => {
            if (res.code === 0) {
              resolve(res.data);
            }
          });
      });
    },
    async getAllCatList(path, defaultList) {
      let _this = this;
      let nowCatList = await _this.getNowCat(0);
      _this.catList = nowCatList;
      if (path[0] && path[0] != 0) {
        const setAllList = async (idList, array) => {
          if (idList.length > 0 && idList.length < 3) {
            let nowList = await _this.getNowCat(path[0]);
            array.forEach((item) => {
              if (item.regionCode == idList[0]) {
                if (nowList.length > 0) {
                  _this.$set(item, 'children', nowList);
                }
              }
            });
            // 执行第三层
            // idList.shift();
            // setAllList(idList,nowList)
          } else {
            _this.$nextTick(() => {
              console.log(defaultList);
              _this.ruleForm.siteList = defaultList.map((item) => Number(item));
            });
          }
        };
        setAllList(path, _this.catList);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.outer {
  position: relative;
}
.result-content{
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 4px;
    padding: 10px 20px;
    border: 1px solid #D3DCE6;
    line-height: 38px;
    .result-status{
        padding-right: 20px;
        padding-top: 13px;
        i{
            font-size: 50px;
        }
    }
}
.bank-info{
    padding: 20px 0;
}
.red{
  color: #FF4949;
}
.orange{
  color: #F7BA2A;
}
.green{
  color: #13CE66;
}
.remark {
  color: #333;
}
.open-img{
  width: 300px;
  height: 200px;
}
</style>
