var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _c("DetailTitle", { attrs: { title: "收款账户信息" } }),
      _vm.pageType !== "add"
        ? _c(
            "div",
            {
              staticStyle: {
                paddingLeft: "10px",
                marginBottom: "20px",
                fontWeight: "700",
                fontSize: "16px",
              },
            },
            [
              _vm._v(
                " 审核状态： " +
                  _vm._s(
                    _vm.form.status == 1
                      ? "待审核"
                      : _vm.form.status == 2
                      ? "审核通过"
                      : `审核不通过${
                          _vm.form.auditNote !== null &&
                          _vm.form.auditNote !== "" &&
                          _vm.form.auditNote !== "null"
                            ? "（" + _vm.form.auditNote + "）"
                            : ""
                        }`
                  ) +
                  " "
              ),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "result-content" }, [
        _c("div", { staticClass: "result-status" }, [
          _c("i", { class: `iconfont ${_vm.tip.icon}` }),
        ]),
        _c("div", { staticClass: "result-info" }, [
          _c("h3", { staticStyle: { "font-size": "16px" } }, [
            _vm._v(" " + _vm._s(_vm.tip.title) + " "),
          ]),
          _c(
            "p",
            { style: { color: _vm.form.status === -1 ? "#000" : "red" } },
            [_vm._v(" " + _vm._s(_vm.tip.content) + " ")]
          ),
        ]),
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "bank-info",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "150px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "收款单位名称：", prop: "receiveUnitName" } },
            [
              _c("el-input", {
                staticClass: "input-w300",
                attrs: { placeholder: "请输入公司名称" },
                model: {
                  value: _vm.form.receiveUnitName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "receiveUnitName", $$v)
                  },
                  expression: "form.receiveUnitName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "收款账号：", prop: "receiveAccount" } },
            [
              _c("el-input", {
                staticClass: "input-w300",
                attrs: { placeholder: "请输入收款账号" },
                model: {
                  value: _vm.form.receiveAccount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "receiveAccount", $$v)
                  },
                  expression: "form.receiveAccount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "收款账户开户行：", prop: "accountBankName" } },
            [
              _c("el-input", {
                staticClass: "input-w300",
                attrs: { placeholder: "请填写开户行全称" },
                model: {
                  value: _vm.form.accountBankName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "accountBankName", $$v)
                  },
                  expression: "form.accountBankName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "银行名称：", prop: "bankCode" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择银行" },
                  model: {
                    value: _vm.form.bankCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "bankCode", $$v)
                    },
                    expression: "form.bankCode",
                  },
                },
                _vm._l(_vm.bankList, function (item) {
                  return _c("el-option", {
                    key: item.bankCode,
                    attrs: { label: item.bankName, value: item.bankCode },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "开户行省市：", prop: "areaList" } },
            [
              _c("el-cascader", {
                ref: "areaRef",
                staticClass: "width360",
                attrs: {
                  options: _vm.catList,
                  props: {
                    checkStrictly: true,
                    value: "regionCode",
                    label: "regionName",
                    expandTrigger: "hover",
                  },
                  clearable: "",
                  size: "mini",
                  placeholder: "请选择",
                },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.form.areaList,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "areaList", $$v)
                  },
                  expression: "form.areaList",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "行联号：", prop: "bankJointCode" } },
            [
              _c("el-input", {
                staticClass: "input-w300",
                attrs: {
                  placeholder: "请填写行联号",
                  maxlength: "20",
                  "show-word-limit": "",
                },
                on: {
                  input: function ($event) {
                    _vm.form.bankJointCode = _vm.form.bankJointCode.replace(
                      /\D/g,
                      ""
                    )
                  },
                },
                model: {
                  value: _vm.form.bankJointCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "bankJointCode", $$v)
                  },
                  expression: "form.bankJointCode",
                },
              }),
              _c(
                "a",
                {
                  staticClass: "table-controller",
                  attrs: {
                    href: "https://www.cwjyz.com.cn/bank/index.html",
                    target: "_blank",
                  },
                },
                [_vm._v("查询参考：https://www.cwjyz.com.cn/bank/index.html")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "开户许可证(或“基本存款账户信息”、“单位结算卡”)：",
                prop: "openPermitImgPath",
              },
            },
            [
              _c("LoadImgOss", {
                attrs: { multiple: false, priview: false },
                on: { onSuccess: _vm.handleImgChange },
              }),
              _vm.form.openPermitImgPath
                ? _c("div", { staticStyle: { "margin-top": "20px" } }, [
                    _c("img", {
                      staticClass: "open-img",
                      attrs: { src: _vm.form.openPermitImgPath, alt: "" },
                    }),
                  ])
                : _vm._e(),
              _c("p", { staticClass: "remark" }, [
                _vm._v(" 只能上传jpg/png文件，大小不超过2Mb，数量仅限1张 "),
              ]),
            ],
            1
          ),
          _vm.pageType !== "add"
            ? _c(
                "el-form-item",
                { attrs: { label: "营业执照：", prop: "licenseImage" } },
                [
                  _c("LoadImgOss", {
                    attrs: { multiple: false, priview: false, disabled: true },
                    on: { onSuccess: _vm.handleLicenseImageChange },
                  }),
                  _vm.form.licenseImage
                    ? _c("div", { staticStyle: { "margin-top": "20px" } }, [
                        _c("img", {
                          staticClass: "open-img",
                          attrs: { src: _vm.form.licenseImage, alt: "" },
                        }),
                      ])
                    : _vm._e(),
                  _c("p", { staticClass: "remark" }, [
                    _vm._v(" 只能上传jpg/png文件，大小不超过2Mb，数量仅限1张 "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.pageType !== "add"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "身份证正面：",
                    prop: "identityCardImageFont",
                  },
                },
                [
                  _c("LoadImgOss", {
                    attrs: { multiple: false, priview: false, disabled: true },
                    on: { onSuccess: _vm.handleIdentityCardImageFontChange },
                  }),
                  _vm.form.identityCardImageFont
                    ? _c("div", { staticStyle: { "margin-top": "20px" } }, [
                        _c("img", {
                          staticClass: "open-img",
                          attrs: {
                            src: _vm.form.identityCardImageFont,
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                  _c("p", { staticClass: "remark" }, [
                    _vm._v(" 只能上传jpg/png文件，大小不超过2Mb，数量仅限1张 "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.pageType !== "add"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "身份证反面：",
                    prop: "identityCardImageBack",
                  },
                },
                [
                  _c("LoadImgOss", {
                    attrs: { multiple: false, priview: false, disabled: true },
                    on: { onSuccess: _vm.handleIdentityCardImageBackChange },
                  }),
                  _vm.form.identityCardImageBack
                    ? _c("div", { staticStyle: { "margin-top": "20px" } }, [
                        _c("img", {
                          staticClass: "open-img",
                          attrs: {
                            src: _vm.form.identityCardImageBack,
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                  _c("p", { staticClass: "remark" }, [
                    _vm._v(" 只能上传jpg/png文件，大小不超过2Mb，数量仅限1张 "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            [
              _vm.form.status !== 4 || _vm.form.status !== 7
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.updateInfo },
                    },
                    [_vm._v(" 提交 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }