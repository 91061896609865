/*
  收款信息填写
*/

// 表单规则
const FORM_RULES = {
  receiveUnitName: [
    { required: true, message: '请输入收款单位', trigger: 'blur' }
  ],
  receiveAccount: [
    { required: true, message: '请输入收款账号', trigger: 'blur' }
  ],
  accountBankName: [
    { required: true, message: '请填写开户行全称，否则无法审核通过', trigger: 'blur' }
  ],
  bankCode: [
    { required: true, message: '请选择银行', trigger: 'blur' }
  ],
  areaList: [
    { required: true, message: '请选择开户行省市', trigger: 'blur', type: 'array' }
  ],

  companyType: [
    { required: true, message: '请选择类型', trigger: 'blur' }
  ],

  openPermitImgPath: [
    { required: true, message: '请上传开户许可证图', trigger: 'blur' }
  ],
  bankCardImage: [
    { required: true, message: '请上传法人银行卡信息', trigger: 'blur' }
  ],
  licenseImage: [
    { required: true, message: '请上传营业执照图', trigger: 'blur' }
  ],
  identityCardImageFont: [
    { required: true, message: '请上传身份证正面照', trigger: 'blur' }
  ],
  identityCardImageBack: [
    { required: true, message: '请上传身份证反面照', trigger: 'blur' }
  ]
}

// 表单值
const FORM_VALUES = {
  companyName: '', // 公司名称
  companyType: 1, // 商家类型
  bankAccount: '', // 税号
  bankName: '', // 开票地址
  suppliers_id: '',
  status: '', // 审核状态 0：审核中 1：审核通过 2：审核未通过
  remark: '',
  openData: { // 开户行
    fileList: [],
    imgCode: []
  },
  openPermitImgPath: '', // 开户许可证
  areaList: ['1100', '1101'],
  siteId: '',
  accountProvince: '',
  csiteId: ''
}

// 信息审核状态
const AUDIT_TYPES = [
  {
    icon: 'iconfenqidengdaishenhe orange',
    title: '收款账户信息审核中',
    content: '您的收款账户信息正在审核中，请稍作等待',
    status: 1
  },
  {
    icon: 'icontongguo green',
    title: '收款账户信息审核通过',
    content: '您的收款账户信息已审核通过，修改后需重新审核',
    status: 1
  },
  {
    icon: 'iconcuowu red',
    title: '收款账户信息审核未通过',
    content: '您的收款账户信息审核未通过，请修改，修改建议：',
    status: 1
  }
]

export {
  FORM_RULES,
  FORM_VALUES,
  AUDIT_TYPES
}
